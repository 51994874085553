import { ILookup } from "@/components/profileForm/ProfileForm";
import axios from "axios";
import { useEffect, useState } from "react";

interface IuseLookupData {
  status: string;
}

const useLookupData = ({ status }: IuseLookupData) => {
  const [regions, setRegions] = useState<ILookup[]>([]);
  const [jobTitles, setJobTitles] = useState<ILookup[]>([]);

  useEffect(() => {
    (async () => {
      if (status === "IncompleteProfile") {
        const regionsResponse = await axios.get<ILookup[]>("/lookup/regions");
        setRegions(regionsResponse.data);

        const jobTitlesResponse = await axios.get<ILookup[]>(
          "/lookup/jobtitles",
        );
        setJobTitles(jobTitlesResponse.data);
      }
    })();
  }, [status]);

  return {
    regions,
    jobTitles,
  };
};

export default useLookupData;
