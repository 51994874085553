import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import theme from "@/styles/theme";

export const LogInGridWrapper = styled(Grid)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;
    ${theme.breakpoints.only("xs")} {
      width: 100%;
      height: 100dvh;
      border-radius: 0;
    }

    ${theme.breakpoints.only("sm")} {
      width: ${(p) => p.theme.spacing(54)};
      height: ${(p) => p.theme.spacing(69.75)};
      border-radius: ${(p) => p.theme.spacing(3)};
    }

    ${theme.breakpoints.up("md")} {
      width: ${(p) => p.theme.spacing(86.25)};
      height: ${(p) => p.theme.spacing(69.75)};
      border-radius: ${(p) => p.theme.spacing(3)};
    }
  }

  & .MuiTypography-titleS .MuiLink-underlineAlways {
    color: #000;
  }

  & .MuiTypography-bodyM .MuiLink-underlineAlways,
  .MuiFormLabel-root {
    color: #000000de;
  }

  & .MuiTypography-bodyM {
    color: #a2a3a4;
  }
`;

export const MarketingWrapper = styled(Box)`
  background: #1a1a1a;
  flex: 1;
  border-radius: ${(p) => p.theme.spacing(3)};
  overflow: hidden;
  padding-top: ${(p) => p.theme.spacing(3)};
  position: relative;
`;
